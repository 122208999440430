import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

const Hero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const heroContent = {
    title: "Let’s Talk",
    description: "Are You Ready to Achieve Your Goals Together?",
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        component="section"
        sx={{
          position: "relative",
          width: "95%",
          height: isMobile ? "300px" : "500px",
          overflow: "hidden",
          backgroundColor: "#fff",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* Background Image */}
        <Box
          component="img"
          src="/getintouch.png"
          alt="Two people shaking hands, ready to achieve goals together. Let's talk about success."
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: 0,
          }}
        />

        {/* Background Overlay with Diagonal Gradient */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background:
              "linear-gradient(135deg, rgba(0, 0, 0, 0.86) 0%, rgba(10, 10, 10, 0.48) 100%)",
            zIndex: 1,
          }}
        />

        {/* Centered Content */}
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
            textAlign: "center",
            padding: "20px",
          }}
        >
          {/* Title */}
          <Typography
            variant="h2"
            sx={{
              fontSize: isMobile ? "52px" : "72px",
              color: "#fff",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            {heroContent.title}
          </Typography>

          {/* Description */}
          <Typography
            sx={{
              fontSize: isMobile ? "25px" : "30px",
              color: "#fff",
              fontWeight: 300,
            }}
          >
            {heroContent.description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
