import React from "react";
import { Box } from "@mui/material";
import LetsTalkSection from "../components/Landing Page/letsTalkBox";
import Services from "../components/Service Page/Service";

const ServicePage = () => {
  return (
    <Box sx={{ width: "100%", minHeight: "100vh" }}>
      <Services />
      <LetsTalkSection />
    </Box>
  );
};

export default ServicePage;
