import React from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import ArrowForward from "@mui/icons-material/ArrowForward";

const statsData = [
  {
    number: "5",
    suffix: "+",
    title: "Year of Experience",
    description: "Digital Transformation",
  },
  {
    number: 50,
    suffix: "+",
    title: "Delivered Solution",
    description: "AI & Cloud Enablement",
  },
  {
    number: 20,
    suffix: "+",
    title: "Happy Customers",
    description: "Across the World",
  },
];

function WhoWeAre() {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
 const is4K = useMediaQuery("(min-width: 3840px)");
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.3 });

  return (
    <Box
      sx={{
        bgcolor: "#F2F3F5",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* Main Content Container */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          bgcolor: "#F2F3F5",
          pl: { xs: 2, sm: 2, md: is4K?'1200px': "210px" },
          pr: { xs: 2, sm: "0px", md:is4K?'1200px':  "210px" },
          py: { xs: 4, sm: 6, md: 8 },
        }}
      >
        <Grid
          container
          spacing={8}
          sx={{ maxWidth: "xl ", alignItems: "center" }}
        >
          {/* Left Side Image */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              component="img"
              src="whoweare.png"
              alt="A team collaborating at a table, using laptops to develop solutions for innovation and growth"
              sx={{
                width: { xs: "85%", sm: "79%", md: "100%" },
                height: "auto",
                maxHeight: "700px",
                objectFit: "cover",
                borderRadius: "15px",
              }}
            />
          </Grid>

          {/* Right Side Content */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              textAlign: { xs: "left", sm: "left" },
              marginRight: { xs: "30px", sm: "90px", md: "0px" },
              marginLeft: { xs: "65px", sm: "84px", md: "0px" },
            }}
          >
            <Typography
              sx={{
                fontSize: isMobile ? "16px" : isTablet ? "18px" : "18px", // Adjust font size
                textTransform: "uppercase",
                color: "#0087B4",
                fontWeight: 600,
              }}
            >
              Who we are
            </Typography>
            <Typography
              sx={{
                fontSize: isMobile ? "35px" : isTablet ? "40px" : "42px", // Adjust font size
                textTransform: "capitalize",
                lineHeight: "1.2",
                mt: 1.5,
                fontWeight: 400,
              }}
            >
              We offer tailored AI-driven solutions designed to accelerate your company's growth and innovation

            </Typography>
            <Typography
            variant="body2"
              sx={{
                // fontSize: isMobile ? "16px" : isTablet ? "18px" : "18px", // Adjust font size
                mt: 3,
                fontWeight: 300
              }}
            >
              DevAngles is a leading global technology service firm with specialization in building custom AI, Data and Cloud Solutions.

            </Typography>
            <Button
              component={Link} // Turns button into a link
              to="/about" // Redirects to the About page
              variant="outlined"
              sx={{
                borderRadius: "50px",
                border: "2px solid #0087B4",
                color: "#0087B4",
                padding: "10px 20px",
                textTransform: "none",
                fontSize: "18px",
                display: "inline-flex",
                alignItems: "center",
                gap: "8px",
                marginTop: "20px",

                transition:
                  "background-color 0.3s ease-in-out, color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "#0087B4",
                  color: "#FFFFFFD9",
                },
                "&:hover .MuiSvgIcon-root": {
                  transform: "translateX(-5px)",
                  opacity: 1,
                  transition:
                    "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
                },
                "&:hover .text": {
                  transform: "translateX(3px)",
                  transition: "transform 0.3s ease-in-out",
                },
              }}
            >
              <Typography
                className="text"
                sx={{
                  transition: "transform 0.3s ease-in-out",
                  fontWeight: 380,
                  fontSize: "18px",
                }}
              >
                Know More
              </Typography>
              <ArrowForward
                sx={{
                  opacity: 0.6,
                  transition:
                    "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
                }}
              />
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Stats Section - Full Width Blue Background */}
      <Box
        ref={ref}
        sx={{
          width: "100%",
          bgcolor: "#0085b1",
          py: { xs: 4, sm: 5, md: 6 },
          color: "white",
        }}
      >
        <Grid
          container
          sx={{
            maxWidth: { xs: "100%", sm: "100%", md: "90%", lg: "90%" },
            mx: "auto",
            px: { xs: 2, sm: 3, md: 4 },
          }}
        >
          {statsData.map((stat, index) => (
            <Grid
              item
              xs={12}
              sm={4}
              key={index}
              sx={{
                borderRight: {
                  xs: "none",
                  sm: index < 2 ? "1px solid rgba(255, 255, 255, 0.2)" : "none",
                },
                py: { xs: 3, sm: 0 },
                textAlign: "center",
                borderBottom: {
                  xs: index < 2 ? "1px solid rgba(255, 255, 255, 0.2)" : "none",
                  sm: "none",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  px: 2,
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontSize: {
                      xs: "36px",
                      sm: "42px",
                      md: "48px",
                      lg: "48px",
                    },
                    fontWeight: 700,
                    mb: 1,
                    lineHeight: 1.1,
                    
                  }}
                >
                  {inView && (
                    <CountUp
                      start={0}
                      end={stat.number}
                      duration={2}
                      suffix={stat.suffix}
                    />
                  )}
                </Typography>
                <Typography
                  sx={{
                    color: "white",
                    fontSize: {
                      xs: "18px",
                      sm: "18px",
                      md: "20px",
                      lg: "20px",
                    },
                    fontWeight: 600,
                    mb: 1,
                    lineHeight: 1.2,
                    
                  }}
                >
                  {stat.title}
                </Typography>
                <Typography
                  sx={{
                    color: "rgba(255, 255, 255, 0.9)",
                    fontSize: {
                      xs: "14px",
                      sm: "14px",
                      md: "16px",
                      lg: "16px",
                    },
                    lineHeight: 1.4,
                    fontWeight: 300
                    
                  }}
                >
                  {stat.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default WhoWeAre;
