import React from "react";
import { Grid, Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Partner = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        padding: "90px",
        width: "100%",
        height: "auto", // Make height flexible
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center", // Center align all content
        paddingX: isMobile ? "20px" : "100px", // Adjust padding based on screen size
      }}
    >
      <Grid
        container
        spacing={3}
        sx={{
          alignContent: "center",
          justifyContent: "center",
          maxWidth: "1200px", // Restrict max width for better responsiveness
          marginX: "auto", // Center content horizontally
        }}
      >
        {/* Text Section */}
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: isMobile ? "16px" : isTablet ? "18px" : "18px", // Adjust font size
              textTransform: "uppercase",
              color: "#0087B4",
              fontWeight: 600,
            }}
          >
            OUR PARTNER
          </Typography>
          <Typography
            sx={{
              fontSize: isMobile ? "35px" : isTablet ? "40px" : "42px", // Adjust font size
              textTransform: "capitalize",
              fontWeight: 400,
            }}
          >
            Proud Partner with Google
          </Typography>
          <Typography
            sx={{
              fontSize: isMobile ? "16px" : isTablet ? "20px" : "26px", // Adjust font size
              fontWeight: 300
            }}
          >
            Empowering Innovation with Google Cloud Solutions
          </Typography>
          <Box
            component="img"
            src="Google_Cloud_Partner_no_outline_vertical.png"
            alt="Google Cloud Partner logo - Empowering innovation with Google Cloud solutions"
            sx={{
              height: { xs: "150px", sm: "200px", md: "300px", lxl: "500px" },
              mt: 3,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Partner;
