import React from "react";
import {
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useInView } from "react-intersection-observer";

// Sample sections with images & content
const sections = [
  {
    title: "Opportunities for Growth",
    subtitle: "Why DevAngles",
    description:
      "We offer endless opportunities for learning, growth, and success. Our dynamic team thrives on innovation, collaboration, and creativity, ensuring a supportive and inspiring work environment.",
    image: "/growth.jpg", // Replace with actual image URL
  },
  {
    title: "Innovative Work Culture",
   
    description:
      "At DevAngles, we foster a culture of innovation and continuous learning. Work on cutting-edge technologies and collaborate with the best minds in the industry.",
    image: "/WorkCulter.jpg",
  },
  
  {
    title: "Strong Community & Collaboration",
    
    description:
      "Be part of a thriving team where knowledge-sharing and collaboration drive success. Your ideas matter, and your contributions make an impact.",
    image: "/teamCollaboration.jpg",
  },
];

function Join() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const is4K = useMediaQuery("(min-width: 3840px)");
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.3 });

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 5,
      }}
    >
      {sections.map((section, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            py: { xs: 4, sm: 3, md: 4 },
            pl: { xs: 2, sm: 2, md: is4K ? "1200px" : "210px" },
            pr: { xs: 2, sm: "0px", md: is4K ? "1200px" : "210px" },
          }}
        >
          <Grid
            container
            spacing={1}
            sx={{ maxWidth: "xl", alignItems: "center" }}
            direction={index % 2 === 0 ? "row" : "row-reverse"} // Toggle image & text side
          >
            {/* Image Section */}
            <Grid item xs={12} md={6}>
              <img
                src={section.image}
                alt={section.title}
                style={{
                  width: "80%",
                  height: "auto",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 10px rgba(0,0,0,0.3)",
                }}
              />
            </Grid>

            {/* Text Section */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                textAlign: { xs: "left", sm: "left" },
                marginRight: { xs: "30px", sm: "90px", md: "0px" },
                marginLeft: { xs: "65px", sm: "84px", md: "0px" },
              }}
            >
              <Typography
                sx={{
                  fontSize: isMobile ? "16px" : isTablet ? "18px" : "18px",
                  textTransform: "uppercase",
                  color: "#0087B4",
                  fontWeight: 600,
                }}
              >
                {section.subtitle}
              </Typography>

              <Typography
                sx={{
                  fontSize: isMobile ? "28px" : isTablet ? "32px" : "36px",
                  textTransform: "capitalize",
                  lineHeight: "1.2",
                  mt: 1.5,
                  fontWeight: 400,
                }}
              >
                {section.title}
              </Typography>

              <Typography
                variant="body2"
                sx={{
                  mt: 3,
                  fontWeight: 300,
                }}
              >
                {section.description}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
}

export default Join;
