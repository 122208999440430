import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  IconButton,
  useMediaQuery,
  useTheme,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Collapse,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForward from "@mui/icons-material/ArrowForward";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Header = () => {
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLaptop = useMediaQuery(theme.breakpoints.up("lg"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(null);
  const [whoWeAreOpen, setWhoWeAreOpen] = useState(false);

  // Navigation items
  const navItems = [
    { text: "Who We Are", path: "/about" },
    // { text: "Insights", path: "/#" },
    //  { text: "Join Us", path: "/joinus" },
  ];

  // Services inside "What We Do"
  const whatWeDoLinks = [
    { text: "Cloud Solutions", path: "/service/cloud-solutions" },
    { text: "Applied AI", path: "/service/applied-ai" },
    { text: "Data Solutions", path: "/service/data-solutions" },
  ];

  // Toggle mobile drawer
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Handle menu open/close on hover
  const handleMenuOpen = (event) => {
    setMenuOpen(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuOpen(null);
  };

  // Toggle "Who We Are" section in the drawer
  const toggleWhoWeAre = () => {
    setWhoWeAreOpen(!whoWeAreOpen);
  };

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        bgcolor: "white",
        color: "black",
        paddingX: isLaptop ? "180px" : "16px",
        top: 0,
        zIndex: 1100,
      }}
    >
      <Container maxWidth="xl" disableGutters>
        {/* Mobile Drawer */}
        <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
          <Box sx={{ bgcolor: "#fff", height: "100%", width: 300 }}>
            <IconButton
              onClick={handleDrawerToggle}
              sx={{ position: "absolute", top: 10, right: 10 }}
            >
              <CloseIcon sx={{ color: "#000" }} />
            </IconButton>
            <List sx={{ marginTop: "60px" }}>
              {/* Dropdown inside Mobile Menu */}
              <ListItem disablePadding>
                <ListItemButton onClick={toggleWhoWeAre}>
                  <ListItemText
                    primary="What We Do"
                    primaryTypographyProps={{
                      fontSize: 16,
                      fontWeight: 200,
                      color: "#000",
                      textTransform: "capitalize",
                    }}
                  />
                  {whoWeAreOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
              </ListItem>

              {/* Services under "Who We Are" */}
              <Collapse in={whoWeAreOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {whatWeDoLinks.map(({ text, path }) => (
                    <ListItemButton
                      key={text}
                      component={Link}
                      to={path}
                      onClick={handleDrawerToggle} // Auto-close on click
                      sx={{ pl: 4 }}
                    >
                      <ListItemText
                        primary={text}
                        primaryTypographyProps={{
                          fontSize: 16,
                          color: location.pathname === path ? "#0087B4" : "#000",
                        }}
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>

              {/* Other Navigation Links */}
              {navItems.map(({ text, path }) => (
                <ListItem key={text} disablePadding>
                  <ListItemButton
                    component={Link}
                    to={path}
                    onClick={handleDrawerToggle}
                  >
                    <ListItemText
                      primary={text}
                      primaryTypographyProps={{
                        fontSize: { xs: 16, sm: 18 },
                        color: location.pathname === path ? "#0087B4" : "#000",

                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
              <ListItem>
                <Button
                  variant="outlined"
                  component={Link} // Turns button into a link
                  to="/contact" // Redirects to the About page
                  sx={{
                    borderRadius: "50px",
                    border: "2px solid #0085B1",
                    color: "#0085B1",
                    padding: isMobile?"10px  20px" :"10px 20px",
                    textTransform: "none",
                    fontSize:isMobile? "16px": "18px !important",
                    display: "flex",
                    boxShadow: "none",
                    alignItems: "center",
                    gap: "8px",
                    marginTop: isMobile?"10px": "20px",
                    fontWeight: 380,
                    transition:
                    "background-color 0.3s ease-in-out, color 0.3s ease-in-out",
                  "&:hover": {
                    backgroundColor: "#0087B4",
                    color: "#FFFFFFD9",
                    boxShadow: "none",
                  },
                  "&:hover .MuiSvgIcon-root": {
                    transform: "translateX(-5px)",
                    opacity: 1,
                    transition:
                      "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
                  },
                  "&:hover .text": {
                    transform: "translateX(3px)",
                    transition: "transform 0.3s ease-in-out",
                  },
                  }}
                >
                  <Typography
                    className="text"
                    sx={{
                      transition: "transform 0.3s ease-in-out",
                    }}
                  >
                    Let's Talk
                  </Typography>
                  <ArrowForward
                    sx={{
                      opacity: 0.6,
                      transition:
                        "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
                    }}
                  />
                </Button>
              </ListItem>
            </List>
          </Box>
        </Drawer>

        {/* Header Section */}
        <Toolbar
          sx={{
            borderBottom: "1px solid #eaeaea",
            padding: { xs: "8px 16px", sm: "12px 24px", md: "16px 32px" },
            display: "flex",
            justifyContent: "space-between",
            height: "102px",
          }}
        >
          {/* Logo */}
          <Link to="/">
            <Box
              component="img"
              src="/logoWithLightBackground.png"
              alt="DevAngles logo – Delivering simple, innovative, and excellent digital IT solutions"
              sx={{
                height: { xs: "18px", sm: "18px", md: "22px" },
                objectFit: "contain",
                cursor: "pointer",
              }}
            />
          </Link>

          {/* Desktop Navigation */}
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              gap: { md: 3, lg: 4 },
            }}
          >
            {/* Dropdown for "What We Do" */}
            <Box>
              <Button
                onClick={handleMenuOpen}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{
                  fontSize: { xs:16, md: 16, lg: 18 },
                  fontWeight: 300,
                  color: "#000",
                  textTransform: "none",
                  "&:hover": {
                    color: "#0087B4",
                    backgroundColor: "transparent", // Prevents hover background change
                  },
                  "&:focus": {
                    backgroundColor: "transparent", // Prevents click background change
                  },
                }}
              >
                What We Do
              </Button>
              <Menu
                anchorEl={menuOpen}
                open={Boolean(menuOpen)}
                onClose={handleMenuClose}
                MenuListProps={{ onMouseLeave: handleMenuClose }}
              >
                {whatWeDoLinks.map(({ text, path }) => (
                  <MenuItem
                    key={text}
                    component={Link}
                    to={path}
                    onClick={handleMenuClose}
                    sx={{color: location.pathname === path ? "#0087B4" : "#000", fontWeight: 300
                    }}
                  >
                    {text}
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            {/* Other Navigation Links */}
            {navItems.map(({ text, path }) => (
              <Typography
                key={text}
                sx={{
                  fontSize: {xs:16, md: 16, lg: 18 },
                  fontWeight: 300,
                  color: location.pathname === path ? "#0087B4" : "#000",

                  "&:hover": { color: "#0087B4" },
                }}
              >
                <Link
                  to={path}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {text}
                </Link>
              </Typography>
            ))}

            {/* "Let's Talk" Button */}
            <Button
              variant="contained"
              component={Link} // Turns button into a link
              to="/contact" // Redirects to the About page
              endIcon={<ArrowForward />}
              sx={{
                bgcolor: "#fff",
                color: "#0085B1",
                border: "2px solid #0085B1",
                borderRadius: "30px",
                padding: "8px 16px",
                marginLeft: 2,
                boxShadow: "none",
                textTransform: "none",
                fontWeight: 380,
                transition:
                  "background-color 0.3s ease-in-out, color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "#0087B4",
                  color: "#FFFFFFD9",
                  boxShadow: "none",
                },
                "&:hover .MuiSvgIcon-root": {
                  transform: "translateX(-5px)",
                  opacity: 1,
                  transition:
                    "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
                },
                "&:hover .text": {
                  transform: "translateX(3px)",
                  transition: "transform 0.3s ease-in-out",
                },
              }}
            >
              Let's talk
            </Button>
          </Box>

          {/* Mobile Menu Icon */}
          <IconButton
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ display: { md: "none" }, color: "#000" }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
