import React from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Grid,
  Card,
} from "@mui/material";
import {
  CloudQueue,
  PsychologyOutlined,
  BarChart,
  DataObject,
  Code,
} from "@mui/icons-material";
import { Helmet } from "react-helmet-async";
const services = {
  "cloud-solutions": {
    hero: {
      title: "Cloud Solutions",
      description: "Scale your Business with Intelligent Cloud Solutions",
      image: "/cloud-main.png",
      alt:"Enterprise cloud computing illustration with interconnected devices and data flow, enabling scalable cloud solutions",
      
    },
    mainContent: {
      title: "Scale your Business with Intelligent Cloud Solutions",
      description:
        "Unlock new levels of efficiency and agility by transitioning to the cloud, modernizing your core applications, and building with cloud-native technologies. This comprehensive strategy will empower your organization to adapt and thrive in today's fast-paced environment.",
      image: "/cloud-content1.png",
      alt: "Woman working on a laptop with a file icon on the screen, representing cloud-based business solutions for efficiency and agility",

    },
    approach: [
      {
        icon: <CloudQueue sx={{ fontSize: 30, color: "#0087B4" }} />,
        title: "Cloud Audit & Advisory",

        points: [
          "Assess your existing cloud setup for efficiency and alignment with best practices",
          "Identify security gaps in your cloud environment.",
          "Reduce cloud spending through analysis and adjustments to resource utilization",
          "Enhance application and infrastructure speed and reliability in the cloud",
          "Develop a strategic plan for your cloud evolution, aligned with business goals",
        ],
      },
      
      {
        title: "Cloud Migration",
        summary:
          "Modernize your legacy applications to leverage the full potential of cloud-native technologies",
        points: [
          "Cloud migration is the process of transferring your business’s data, apps, and IT services from on-premises systems to cloud-based platforms",
          "It gives you access to cloud resources anytime, anywhere, making your business more flexible and responsive",
          "With cloud migration, you can reduce the need for expensive hardware and pay only for what you use",
          "The cloud allows you to easily scale your business as needed, ensuring you can grow without worrying about infrastructure limits",
        ],
      },
      {
        title: "Application Modernization",

        points: [
          "Comprehensive evaluation of existing applications and infrastructure",
          "Choose the appropriate cloud platform.",
          "Redesign legacy applications for cloud compatibility.",
          "Break monolithic applications into microservices",
          "Hands on keyboard with close collaboration with your team",
          "Provide cloud strategies for business innovation, agility, and future growth",
        ],
      },
      {
        title: "Devops as a service",

        points: [
          "Define key metrics and objectives, such as reducing time to market, improving deployment frequency, and increasing automation",
          "Automate infrastructure setup using Infrastructure as Code (IaC) tools like Terraform, CloudFormation, or Ansible",
          "Design and implement automated pipelines for code integration, testing, and deployment",
          "Continuous feedback loop to analyze deployments, detect bottlenecks, and optimize workflows",
          "Ensure client teams are equipped to manage DevOps practices independently post-engagement",
        ],
      },
    ],
    metadata:[
      {
        title: "Cloud Modernization | Google Cloud Services | DevAngles",
        description: "DevAngles provides comprehensive cloud modernization services leveraging Google Cloud. Streamline your infrastructure, modernize legacy apps, and drive digital transformation.",
        keywords: "",
        canonical: "/service/cloud-solutions",
      }
    ],
  },
  "applied-ai": {
    hero: {
      title: "Applied AI",
      description: "Solve the real world complex problem using Applied AI",
      image: "/AIML-main.png",
      alt:"A robot hand touching a human hand, symbolizing AI-human connection",
            
    },
    mainContent: {
      title: "Solve the real world complex problem using Applied AI",
      description:
        "Our AI solutions empower businesses to unlock data-driven insights, automate processes, and deliver transformative customer experiences.",
      image: "/aiml.png",
      alt: "A robotic hand and human finger reaching toward a stylized brain, symbolizing Generative AI and Conversational AI for automation and innovation",

    },
    approach: [
      {
        icon: <PsychologyOutlined sx={{ fontSize: 30, color: "#0087B4" }} />,
        title: "Generative AI",
        summary:
          "Leverage Generative AI to create innovative solutions for your business challenges.",
        points: [
          "Accelerate content production workflows and reduce manual effort",
          "Prototype and iterate on design ideas rapidly",
          "Augment existing datasets to improve model accuracy and robustness",
          "Enhance creativity and explore new artistic possibilities",
          "Leverage AI to assist in scientific discoveries and innovation",
          "Enable communication in multiple languages, making customer support and engagement globally accessible",
        ],
      },
      {
        icon: <CloudQueue sx={{ fontSize: 30, color: "#0087B4" }} />,
        title: "Conversational AI",
        summary:
          "Develop Conversational AI-powered journeys that drive operational efficiency and customer satisfaction.",
        points: [
          "Enable communication in multiple languages, making customer support and engagement globally accessible",
          "Provide 24/7 customer support and instant responses",
          "Improve search functionality and information retrieval",
          "Develop voice-controlled interfaces and applications",
        ],
      },
      {
        icon: <BarChart sx={{ fontSize: 30, color: "#0087B4" }} />,
        title: "Custom AI Solutions",
        summary:
          "Transform raw data into actionable knowledge with our Data Science expertise.",
        points: [
          "Design AI systems specific to a client’s unique business needs, objectives, and use cases",
          "Develop AI models specifically designed for your unique business needs",
          "Predict future trends and outcomes with AI-powered analytics",
          "Automate visual inspection and quality control",
          "Improve business decision making through AI",
        ],
      },
    ],
    metadata:[
      {
        title: "Global AI & ML Solutions | Custom Development | DevAngles",
        description: "DevAngles empowers businesses globally with custom AI & ML solutions. We build tailored artificial intelligence and machine learning applications to drive your success.",
        keywords: "",
        canonical: "/service/applied-ai",
      }
    ],
  },
  "data-solutions": {
    hero: {
      title: "Data Solutions",
      description: "Data is boring but Insights are Interesting",
      image: "/DatSolutionHero.png",
      alt:"Team members working on laptops, transforming data into insightful solutions",
    },
    mainContent: {
      title: "Data is boring but Insights are Interesting",
      description:
        "Transform your data into actionable insights with our comprehensive data analytics services. We help you uncover hidden patterns, identify trends, and make data-driven decisions that drive business growth",
      image: "/dataSolution.png",
      alt:"Three professionals in a business meeting, analyzing data on a laptop with graphs and discussing insights",
    },
    approach: [
      {
        title: "Data Strategy",
        summary:
          "Develop a comprehensive data strategy tailored to your business needs.",
        points: [
          "Develop data strategies aligned with overall business goals",
          "Evaluate and select appropriate data technologies and platforms",
          "Develop efficient data management practices",
          "Foster a culture of data-driven decision-making",
          "Ensure data security and compliance with regulations",
        ],
      },
      {
        icon: <CloudQueue sx={{ fontSize: 30, color: "#0087B4" }} />,
        title: "Data Storage Modernization",
        summary:
          "Integrate data from multiple sources to create a unified view of your business.",
        points: [
          "Transition legacy on-premise storage to cloud-based solutions",
          "Combine on-premise and cloud storage to ensure high availability",
          "Implement modern data warehousing solutions to optimize data storage, retrieval, and analytics processes",
          "Utilize modern storage technologies (e.g., object storage, NoSQL databases)",
        ],
      },
      {
        icon: <DataObject sx={{ fontSize: 30, color: "#0087B4" }} />,
        title: "Data Analytics & Visualization",
        summary:
          "Leverage advanced analytics to uncover insights and drive decision-making.",
        points: [
          "Perform exploratory data analysis and statistical modeling",
          "Develop interactive dashboards and reports",
          "Leverage real-time data processing for immediate insights and rapid decision-making",
          "Embedded analytics within applications",
        ],
      },
    ],
    metadata:[
      {
        title: "Data Modernization | Data Migration Services | DevAngles",
        description: "DevAngles provides comprehensive data modernization and migration services, helping businesses leverage their data for better decision-making and operational efficiency.",
        keywords: "",
        canonical: "/service/data-solutions",
      }
    ],
  },
};

const Services = () => {
  const { serviceId } = useParams();
  const service = services[serviceId];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const metadata = service.metadata[0];
  console.log(metadata);
  if (!service) {
    return <Typography variant="h4">Service not found</Typography>;
  }

  return (
    <>
    {/* Dynamic Metadata for Each Service Page */}
    <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
       
        <link rel="canonical" href={`https://www.devangles.com${metadata.canonical}/`} />
        <link rel="dns-prefetch" href="https://www.devangles.com" />
        <link rel="dns-prefetch" href="//fonts.googleapis.com" />
    </Helmet>
    <Box>
      {/* Hero Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          component="section"
          sx={{
            position: "relative",
            width: "95%",
            height: {
              xs: "350px",
              sm: "400px",
              md: "481px",
              lg: "481px",
            },
            overflow: "hidden",
            backgroundColor: "#fff",
            border: "1px solid #e0e0e0",
            borderRadius: "15px",
          }}
        >
          {/* Background overlay */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "#f8f9fa",
              zIndex: 0,
            }}
          />

          {/* Background Image */}
          <Box
            component="img"
            src={service.hero.image}
            alt={service.hero.alt}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: 0,
            }}
          />

          {/* Centered Content */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1,
              padding: {
                xs: "20px",
                sm: "40px",
                md: "60px",
              },
              textAlign: "center",
              backgroundColor: "rgba(43, 39, 39, 0.7)",
            }}
          >
            {/* Hero Title */}
            <Typography
              variant="h1"
              sx={{
                
                fontWeight: 300,
                color: "#fff",
                marginBottom: "16px",
                maxWidth: {
                  xs: "100%",
                  sm: "80%",
                  md: "70%",
                  lg: "60%",
                },
                fontSize: {
                  xs: "40px",
                  sm: "42px",
                  md: "72px",
                  lg: "72px",
                },
              }}
            >
              {service.hero.title}
            </Typography>

            {/* Hero Description */}
            <Typography
              sx={{
                
                color: "#fff",
                marginBottom: "40px",
                fontWeight: 300,
                maxWidth: {
                  xs: "100%",
                  sm: "80%",
                  md: "70%",
                  lg: "50%",
                },
                fontSize: {
                  xs: "14px",
                  sm: "16px",
                  md: "30px",
                  lg: "30px",
                },
                lineHeight: 1.6,
              }}
            >
              {service.hero.description}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Main Content Section
      <Box
      sx={{
        

        padding: "90px",
        width: "100%",
        height: "auto", // Make height flexible
         
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: isMobile ? "center" : "start",
        // paddingX: isMobile ? "20px" : "85px", // Adjust padding based on screen size
        pl: { xs: 2, sm: 3, md: "84px" },
        pr: { xs: 2, sm: 2.5, md: "115px" },
      }}
      >
        <Grid
          container
          spacing={4}
          alignItems="flex-start"
          sx={{ maxWidth: "1200px" }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "32px", md: "42px" },
                fontWeight: 300,
                marginBottom: "24px",
                color: "#333",
                
              }}
            >
              {service.mainContent.title}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "16px", md: "18px" },
                color: "#555",
                lineHeight: 1.6,
                
                fontWeight: 300,
              }}
            >
              {service.mainContent.description}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={service.mainContent.image}
              alt={service.mainContent.title}
              sx={{ width: "100%", height: "auto", borderRadius: "15px" }}
            />
          </Grid>
        </Grid>
      </Box> */}

      {/* Our Approach Section */}

      <Box
        sx={{
          padding: "90px",
          width: "100%",
          height: "auto", // Make height flexible
          
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: isMobile ? "center" : "start",
          // paddingX: isMobile ? "20px" : "85px", // Adjust padding based on screen size
          pl: { xs: 2, sm: 3, md: "84px" },
          pr: { xs: 2, sm: 2.5, md: "115px" },
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{
            maxWidth: "xl", // Restrict max width for better responsiveness
            marginX: { xs: "20px", sm: "50px", md: "100px", lg: "140px" },
            alignItems: "flex-start",
          
          }}
        >
          {/* Text Section */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "32px", sm:'42px', md: "42px" },
                fontWeight: 400,
                marginBottom: "24px",
                color: "#333",
                
              }}
            >
              {service.mainContent.title}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: { xs: "16px",m:'18px', md: "18px" },
                color: "#555",
                lineHeight: 1.6,
                
                fontWeight: 300,
              }}
            >
              {service.mainContent.description}
            </Typography>
          </Grid>

          {/* Button Section */}
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: isMobile ? "20px" : 0, // Add spacing on small screens
            }}
          >
            <Box
              component="img"
              src={service.mainContent.image}
              alt={service.mainContent.alt}
              sx={{
                width: "100%", // Makes the image responsive

                height: "auto", // Maintains aspect ratio
                borderRadius: "15px", // Optional: Adds rounded corners
                justifyContent: "end",
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          padding:"100px",
          paddingTop:'50px !important',
          width: "100%",
          height: "auto",
          display: "flex",
          alignContent:'center',
          justifyContent:'center',
         // bgcolor: "#FAFBFC",
          paddingX: isMobile ? "20px" : "100px",
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{ maxWidth: "1450px", marginX: "auto" }}
        >
          {service.approach.map((card, index) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              key={index}
              sx={{ display: "flex" }}
            >
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  padding: "20px",
                  borderRadius: "5px",
                  boxShadow:'none',
                 border:'1px solid #B0B0B0',
                  //boxShadow: " rgba(0, 0, 0, 0.15) 0px 2px 8px", // Darker shadow for depth
                  mt: "25px",
                   
                  flexGrow: 1, // Ensures equal height
                  height: "100%", // Forces uniform height
                  transition: "all 0.3s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.05)", // Slightly enlarges the card
  transition: "transform 0.3s ease-in-out", // Smooth animation
                  },
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                    marginBottom: 1,
                    
                  }}
                >
                  {card.title}
                </Typography>

                <Box sx={{ flexGrow: 1 }}>
                  <ul
                    style={{
                      fontSize: "18px",
                      
                      paddingLeft: "20px",
                      margin: 0,
                      listStyleType: "disc",
                      
                    }}
                  >
                    {card.points.map((point, i) => (
                      <li
                        key={i}
                        style={{
                          color: "#0085B1", // Bullet point color
                          fontWeight: 200,
                          marginBottom: "10px",
                        }}
                      >
                        <span style={{ color: "#000", fontWeight:300, opacity: 0.8 }}>
                          {point}
                        </span>
                      </li>
                    ))}
                  </ul>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
    </>
  );
};

export default Services;
