import React from "react";
import { Grid, Box, Button, Typography, useMediaQuery } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

const LetsTalkSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        backgroundColor: "#0087B4",

        color: "#FFFFFFD9",
        padding: "60px",
        width: "100%",
        height: "auto",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: isMobile ? "center" : "start",
        paddingX: isMobile ? "20px" : "100px", // Adjust padding based on screen size
      }}
    >
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{
          maxWidth: "1200px", // Restrict max width for better responsiveness
          marginX: { xs: "20px", sm: "50px", md: "100px", lg: "140px" },
        }}
      >
        {/* Text Section */}
        <Grid item xs={12} md={10} lg={10}>
          <Typography
            sx={{
              fontSize: isMobile ? "28px" : isTablet ? "36px" : "42px", // Adjust font size
              fontWeight:300
            }}
          >
           Are You Ready to Achieve Your Goals Together?
          </Typography>
        </Grid>

        {/* Button Section */}
        <Grid
          item
          xs={12}
          md={2}
          lg={2}
          sx={{
            display: "flex",
            justifyContent: isMobile ? "center" : "flex-start", // Center button on mobile
            marginTop: isMobile ? "20px" : 0, // Add spacing on small screens
          }}
        >
          <Button
            variant="outlined"
            component={Link} // Turns button into a link
            to="/contact"
             
            sx={{
              borderRadius: "50px",
              border: "2px solid #FFFFFFD9",
              color: "#FFFFFFD9",
              padding: "10px 20px",
              textTransform: "none",
              fontSize: "18px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              
              fontWeight: 200,
              transition:
                "background-color 0.3s ease-in-out, color 0.3s ease-in-out",
              "&:hover": {
                backgroundColor: "#FFFFFFD9",
                color: "#0087B4",
                border: "2px solid #FFFFFFD9",
              },
              "&:hover .MuiSvgIcon-root": {
                transform: "translateX(-5px)",
                opacity: 1,
                transition:
                  "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
              },
              "&:hover .text": {
                transform: "translateX(3px)",
                transition: "transform 0.3s ease-in-out",
              },
            }}
          >
            <Typography
              className="text"
              sx={{
                transition: "transform 0.3s ease-in-out",
                fontWeight:300
              }}
            >
              Let's Talk
            </Typography>
            <ArrowForward
              sx={{
                opacity: 0.6,
                transition:
                  "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
              }}
            />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LetsTalkSection;
