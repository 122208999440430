// src/routes/Routes.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import AboutPage from "../pages/AboutUs";
import ContactUs from "../pages/ContactUs";
import JoinUs from "../pages/JoinUs";
import Scroll from "../components/Scroll";
import ServicesPage from "../pages/ServicePage"
import NotFound from "../pages/notfound"
const AppRoutes = () => {
  return (
    <>
    <Scroll/>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/contact" element={<ContactUs />} />
      {/* <Route path="/joinus" element={<JoinUs />} /> */}
      <Route path="/service/:serviceId" element={<ServicesPage />} />
      <Route path="*" element={<NotFound />} />

    </Routes>
    </>
  );
};

export default AppRoutes;