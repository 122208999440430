import React from "react";
import Hero from "../components/Landing Page/Hero";
// import About from "../About";

import Services from "../components/Landing Page/ServiceSection";
import WhoWeAre from "../components/Landing Page/WhoWeAre";
import LetsTalkSection from "../components/Landing Page/letsTalkBox";
import { ThemeProvider } from "@emotion/react";
import theme from "../components/theme/Theme";
// import OurApproach from "../components/Landing Page/ourApproach";
import Partner from "../components/Landing Page/partner";
import { Helmet } from "react-helmet-async";
const Home = () => {
  return (
    <>
      {/* metadata for SEO */}
      <Helmet>
        <title>Cloud & AI Technology Service Provider | DevAngles | Google Cloud Partner | India | Global</title>
        <meta name="description" content="Devangles is a leading global AI and cloud technology solution provider. We specialize in Google Cloud, offering cloud migrations, DevOps, security reviews, machine learning, data analytics, generative AI, chatbots, and SaaS app development services. Get a consultation today." />
         
        <link rel="canonical" href="https://www.devangles.com/" />
        <link rel="dns-prefetch" href="https://www.devangles.com" />
        <link rel="dns-prefetch" href="//fonts.googleapis.com" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <Hero />

        <Services />
        <WhoWeAre />
        <Partner />
        {/* <OurApproach /> */}
        <LetsTalkSection />
      </ThemeProvider>
    </>
  );
};

export default Home;
