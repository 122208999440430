
import React from "react";
import Header from "../Header/Header";
import Footer from "../footer/Footer";
import { Box } from "@mui/material";
const Layout = ({ children }) => {
  return (
    <Box>
      <Header />  
      <Box sx={{ padding: 0 }}>
        {children}  
      </Box>
      <Footer /> 
    </Box>
  );
};

export default Layout;
