import React from "react";
import { Grid, Box, Typography, useMediaQuery, Card,Container } from "@mui/material";
import {
  ArrowForward,
  CloudQueue,
  Psychology,
  BarChart,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

const OurApproach = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery(theme.breakpoints.up("lg"));
  const is4K = useMediaQuery("(min-width: 3840px)");

  const cards = [
    {
      id: "cloud-solutions",
      icon: <CloudQueue sx={{ fontSize: 30, color: "#0087B4" }} />,
      title: "Cloud Solutions",
      summary: "Drive your digital transformation with a comprehensive strategy that encompasses cloud migration, application modernization, and the adoption of cloud-native technologies.",
    },
    {
      id: "applied-ai",
      icon: <Psychology sx={{ fontSize: 30, color: "#0087B4" }} />,
      title: "Applied AI",
      summary:
        "Accelerate your future transformation with AI, Machine Learning, and Generative AI solutions, all backed with DevAngles expertise.",
    },
    {
      id: "data-solutions",
      icon: <BarChart sx={{ fontSize: 30, color: "#0087B4" }} />,
      title: "Data Solutions",
      summary: "Develop a tailored data strategy and execute it flawlessly by implementing the appropriate data technologies.",
    },
  ];

  return (
    <Box
    sx={{
      width: "100%",
      height: "auto",
      display: "flex",
      pl:{xs:2,sm:3,md: is4K? "1000px":"197px"},
      pr:{xs:2,sm:2.5,md:is4K? "1000px":"197px"},
      flexDirection: "column",
    }}
  >
    <Container
      maxWidth="xl"
      disableGutters
      sx={{
        paddingX: "16px", // Match header padding
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          alignContent: "center",
          justifyContent: "center",
          textAlign: "center",
          paddingTop: "80px", // Add top padding instead of overall padding
        }}
      >
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: isMobile ? "16px" : isTablet ? "18px" : "18px",
              textTransform: "uppercase",
              color: "#0087B4",
              mt: 4,
              fontWeight: 500,
            }}
          >
            Our Capabilities
          </Typography>
          <Typography
            sx={{
              fontSize: isMobile ? "35px" : isTablet ? "40px" : "42px",
              textTransform: "capitalize",
              marginBottom: 2,
              fontWeight: 400,
            }}
          >
            Building Scalable AI Cloud Solutions
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ paddingBottom: "80px" }}>
        {cards.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Link
              to={`/service/${card.id}`}
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                height: "100%", // Make the link fill the grid item
              }}
            >
              <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      padding: "20px",
                      borderRadius: "0px",
                      mt: "25px",
                      transition: "all 0.3s ease-in-out",
                      flexGrow: 1, // Ensures all cards stretch equally
                      "&:hover": {
                        backgroundColor: "#F2F3F5",
                      },
                    }}
                  
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 600, marginBottom: 1 }}
                >
                  {card.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    opacity: 0.8,
                    marginBottom: 2,
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: 4,
                    fontWeight: 300,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {card.summary}
                </Typography>
                <Box sx={{ marginTop: "auto" }}> {/* Push arrow to bottom */}
                  <ArrowForward
                    sx={{
                      color: "#0085B1",
                      transition: "transform 0.3s",
                    }}
                  />
                </Box>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  </Box>
  );
};

export default OurApproach;