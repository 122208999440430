import React from 'react';
import { 
  Box, 
  Typography, 
  Container,
  useMediaQuery,
  useTheme
} from '@mui/material';

const About = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLaptop = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  // Dynamic font sizes based on screen size
  const getFontSize = (type) => {
    if (type === 'heroTitle') {
      if (isMobile) return '32px';
      if (isTablet || isLaptop) return '42px';
      if (isLargeScreen) return '42px';
      return '42px';
    }
    if (type === 'heroSubtitle') {
      if (isMobile) return '20px';
      if (isTablet || isLaptop) return '24px';
      if (isLargeScreen) return '24px';
      return '24px';
    }
    return '16px'; // Default
  };

  return (
    <Box sx={{ 
      py: 8
    }}>
      <Container maxWidth="lg">
        <Box textAlign="center" mb={6}>
          <Typography 
            component="h1"
            sx={{ 
              fontSize: getFontSize('heroTitle'),
              fontWeight: 400,
              mb: 4,
              mt:2,
              color: '#333',
            }}
          >
            About DevAngles
          </Typography>
          <Typography 
            component="p"
            sx={{ 
              fontSize:isMobile?'22px': '28px',             
              fontWeight: 200,
              mb: 2,
              color: '#000',
              opacity:0.9,
              lineHeight: 1.6
            }}
          >
            At DevAngles, We empower businesses by using cloud, data, and AI to unlock new opportunities and improve customer experiences. We prioritize client commitment, excellence, innovation, and efficient, reliable solutions, ensuring clients are future-proofed and experience continuous growth.

          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default About;