import React from "react";
import {
  Box,
  Typography,
 
  useTheme,
  useMediaQuery,
} from "@mui/material";

const Hero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const is4K = useMediaQuery("(min-width: 3840px)");
  // Set content directly without loading state
  const heroContent = {
    title: "Building Future ready AI Powered Cloud Solutions",
    description:
      "We are helping organizations unlock new business opportunities and enhance customer journeys with cloud, data, and AI-driven insights",
    buttonText: "Let's talk",
    buttonLink: "/contact",
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      
      }}
    >
      <Box
        component="section"
        sx={{
          position: "relative",
          width: "95%",
          height: {
            xs: "500px",
            sm: "600px",
            md: "982px",
            lg: "982px",
          },
          overflow: "hidden",
          backgroundColor: "#fff",
          borderRadius: "15px",
        }}
      >
        {/* Background overlay */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "#f8f9fa",
            zIndex: 0,
          }}
        />

        {/* Background Image */}
        <Box
          component="video"
          src="hero.webm"
          autoPlay
          loop
          muted
          playsInline
          preload="auto"
          aria-label="Building future-ready AI-powered cloud solutions to drive digital transformation with cloud, AI, and data-driven insights."
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: 0,
          }}
        />

        {/* Centered Content */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
            padding: {
              xs: "20px",
              sm: "40px",
              md: "60px",
            },
            textAlign: "center",
            backgroundColor: "rgba(31, 28, 28, 0.7)",
          }}
        >
          {/* Hero Title */}
          <Typography
            variant="h1"
            sx={{
              fontWeight: "bold",
              color: "#fff",
              marginBottom: "16px",
              maxWidth: {
                xs: "100%",
                sm: "80%",
                md: "70%",
                lg: is4K ? "40%" : "60%",
              },
              fontSize: {
                xs: "40px",
                sm: "42px",
                md: "72px",
                lg: "72px",
              },
            }}
          >
            {heroContent.title}
          </Typography>

          {/* Hero Description */}
          <Typography
            sx={{
              color: "#fff",
              marginBottom: "40px",
              maxWidth: {
                xs: "100%",
                sm: "80%",
                md: "70%",
                lg: is4K ? "40%" : "70%",
              },
              fontSize: {
                xs: "14px",
                sm: "16px",
                md: "30px",
                lg: "30px",
              },
              lineHeight: 1.6,
              fontWeight: 300
            }}
          >
            {heroContent.description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
