import { createTheme } from "@mui/material/styles";
import "@fontsource/lexend/300.css"; // ExtraLight (200)

const theme = createTheme({
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 600,
  //     md: 900,
  //     lg: 1920,
  //     xl: 3840,
  //   },
  // },
  typography: {
    fontFamily: "Lexend",
    // fontWeightLight: 300,  
    // fontWeightRegular: 400,  
    // allVariants: {
    //   fontWeight: 400, // Applies 200 to all typography variants
    // },
  },
});

export default theme;
