import React from "react";
import {
  Grid,
  Divider,
  Typography,
  Box,
  Link,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Facebook, Twitter, Instagram } from "@mui/icons-material";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link as RouterLink } from "react-router-dom";

export default function Footer() {
  const theme = useTheme();
  const isLarge = useMediaQuery("(min-width: 1920px)");
  const isUltraWide = useMediaQuery("(max-width: 2560px)");
  const is2XLarge = useMediaQuery("(min-width: 3840px)");
  const isMobile = useMediaQuery("(max-width: 495px)");
  const isTablet = useMediaQuery("(max-width: 768px)");

  // Services and Company links with React Router paths
  const servicesData = [
    { title: "Cloud Solutions", to: "/service/cloud-solutions" },
    { title: "Applied AI", to: "/service/applied-ai" },
    { title: "Data Solutions", to: "/service/data-solutions" },
  ];
  const Company = [
    { title: "About DevAngles", to: "/about" },
    { title: "Let's Talk", to: "/contact" },
    // { title: "Join Us", to: "/joinus" },
  ];

  return (
    <Box sx={{ backgroundColor: "#00002D", padding: "40px 0", width: "100%" }}>
      <Box
        sx={{
          maxWidth: "100%",
          margin: isMobile ? "50px 0px" : is2XLarge ? "50px 850px" : isTablet? "50px 0":  "50px 70px",
          paddingX: isTablet? '4%':"8%",
        }}
      >
        <Grid container spacing={2.5} justifyContent="space-between">
          {/* Logo & Description */}
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Box
              component="img"
              src="/DarkBackground.png"
              alt="DevAngles logo – Delivering simple, innovative, and excellent digital IT solutions"
              sx={{
                width: is2XLarge ? "350px" : isLarge ? "350px" : isTablet ? "150px" : "250px",
                height: "auto",
                marginBottom: 2,
              }}
            />
            <Typography sx={{ color: "#FFFFFFDB", fontSize: "16px",fontWeight:300 }}>
              We help organizations unlock new business opportunities and enhance
              customer journeys with cloud, data, and AI driven insights.
            </Typography>
          </Grid>

          {/* Services Links */}
          <Grid item xs={12} sm={6} md={6} lg={2}>
            <QuickLinks
              title="What We Do"
              links={servicesData}
              fontSize={
                is2XLarge
                  ? "33px"
                  : isLarge
                  ? "18px"
                  : isMobile
                  ? "16px"
                  : isTablet
                  ? "18px"
                  : "20px"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2}>
            <QuickLinks
              title="Who We Are"
              links={Company}
              fontSize={
                is2XLarge
                  ? "33px"
                  : isLarge
                  ? "18px"
                  : isMobile
                  ? "16px"
                  : isTablet
                  ? "18px"
                  : "20px"
              }
            />
          </Grid>
          {/* Contact Us section */}
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <ContactInfo
              fontSize={
                is2XLarge
                  ? "33px"
                  : isLarge
                  ? "18px"
                  : isMobile
                  ? "16px"
                  : isTablet
                  ? "18px"
                  : "20px"
              }
            /> 
          </Grid>
        </Grid>

        {/* Footer Bottom */}
        <Divider sx={{ my: 4, borderColor: "rgba(255, 255, 255, 0.86)" }} />
        <Typography
          // variant="body2"
          sx={{
            color: "#FFFFFFD9",
            textAlign: "end",
            fontWeight: 300,
            fontSize: is2XLarge
              ? "20px"
              : isLarge
              ? "16px"
              : isMobile
              ? "12px"
              : isTablet
              ? "12px"
              : "14px",
          }}
        >
          © {new Date().getFullYear()} Devangles. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
}
function QuickLinks({ title, links, fontSize }) {
  const isLarge = useMediaQuery("(min-width: 1920px)");
  const isUltraWide = useMediaQuery("(max-width: 2560px)");
  const is2XLarge = useMediaQuery("(min-width: 3840px)");
  const isMobile = useMediaQuery("(max-width: 495px)");
  const isTablet = useMediaQuery("(max-width: 768px)");
  return (
    <Box sx={{ color: "#FFFFFFD9" }}>
      <Typography variant="h6" sx={{ fontWeight: 500, marginBottom: 1.5, }}>
        {title}
      </Typography>
      <Box>
        {links.map((link, index) => (
          <Link
            key={index}
            component={RouterLink} // Enables routing
            to={link.to} // Navigates to the correct service page
            sx={{
              display: "block",
              color: "#FFFFFFD9",
              textDecoration: "none",
              marginBottom: 2.7,
              fontSize: is2XLarge
                ? "20px"
                : isLarge
                ? "14px"
                : isMobile
                ? "12px"
                : isTablet
                ? "12px"
                : "14px",
              "&:hover, &:focus": {
                textDecoration: "underline",
              },
            }}
          >
            {link.title}
          </Link>
        ))}
      </Box>
    </Box>
  );
}
function ContactInfo({ fontSize }) {
  const isLarge = useMediaQuery("(min-width: 1920px)");
  const isUltraWide = useMediaQuery("(max-width: 2560px)");
  const is2XLarge = useMediaQuery("(min-width: 3840px)");
  const isMobile = useMediaQuery("(max-width: 495px)");
  const isTablet = useMediaQuery("(max-width: 768px)");
  return (
    <Box sx={{ color: "#FFFFFFD9" }}>
      <Typography variant="h6" sx={{ fontWeight: 500, marginBottom: 1.5, }}>
        Contact Us
      </Typography>
      <Box>
         
        <Typography
          variant="body1"
          sx={{
            fontWeight: 300,
            marginBottom: 1.5,
            fontSize: is2XLarge
              ? "20px"
              : isLarge
              ? "14px"
              : isMobile
              ? "12px"
              : isTablet
              ? "12px"
              : "14px",
          }}
        >
          <strong>Phone:</strong>{" "}
          <Link href="tel:+6545667543" sx={{ color: "#FFFFFFD9", textDecoration: "none" }}>
            +91-20-4863 6888
          </Link>
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 300,
            fontSize: is2XLarge
              ? "20px"
              : isLarge
              ? "14px"
              : isMobile
              ? "12px"
              : isTablet
              ? "12px"
              : "14px",
          }}
        >
          <strong>Email:</strong>{" "}
          <Link href="mailto:contact@devangles.com" sx={{ color: "#FFFFFFD9", textDecoration:'none' }}>
            contact@devangles.com
          </Link>
        </Typography>

        {/* Social Links */}
        <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
          <Link href="https://in.linkedin.com/company/dev-angles" aria-label="LinkedIn">
            <LinkedInIcon sx={{ fontSize,color: "#FFFFFFD9" }} />
          </Link>
          <Link href="#" aria-label="Twitter">
            <Twitter sx={{ fontSize,color: "#FFFFFFD9" }} />
          </Link>
          <Link href="#" aria-label="Instagram">
            <Instagram sx={{ fontSize,color: "#FFFFFFD9" }} />
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
