import React from "react";
import Hero from "../components/Contact Us/GetInTouch";
import LetsTalk from "../components/Contact Us/Let'sTalk";
import { Helmet } from "react-helmet-async";
const ContactUs = () => {
  return (
    <>
      {/*metadata for SEO */}
      <Helmet>
        <title>Contact | DevAngles</title>
        <meta name="description" content="Connect with DevAngles." />
       
        <link rel="canonical" href="https://www.devangles.com/contact/" />
        <link rel="dns-prefetch" href="https://www.devangles.com" />
        <link rel="dns-prefetch" href="//fonts.googleapis.com" />
      </Helmet>
      <Hero />
      <LetsTalk />
    </>
  );
};

export default ContactUs;
