import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  Container,
  Paper,
  useTheme,
  useMediaQuery,
  Divider,
  Snackbar,
  Alert
   
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import axios from "axios";

const ContactFormComponent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLaptop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const [errors, setErrors] = useState({});
  const [responseMessage, setResponseMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  // State to store form data

  
  // Google Maps Embed Links
  const locations = {
    Pune: "https://www.google.com/maps/embed/v1/place?q=city+vista+kharadi+pune+maharashtra&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
    Ahmedabad:
      "https://www.google.com/maps/embed/v1/place?q=+Rudram+Icon,+Nr.+Silver+Oak+University,+Gota,+Ahmedabad,+Gujarat&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8",
  };
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    company: "",
    message: "",
  });

  // Regex patterns
  const nameRegex = /^[A-Za-z\s]+$/; // Only letters and spaces
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Email format
  const phoneRegex = /^[0-9]{10}$/; // Exactly 10-digit numbers

  // Validation function
  const validateForm = () => {
    let newErrors = {};

    // First Name Validation
    if (!formData.first_name.trim()) {
      newErrors.first_name = "First Name is required.";
    } else if (!nameRegex.test(formData.first_name)) {
      newErrors.first_name = "Only Characters are allowed.";
    } else if (formData.first_name.trim().length < 2) {
      newErrors.first_name = "Must contain at least 3 letters.";
    }

    // Last Name Validation
    if (!formData.last_name.trim()) {
      newErrors.last_name = "Last Name is required.";
    } else if (!nameRegex.test(formData.last_name)) {
      newErrors.last_name = "Only Characters are allowed.";
    } else if (formData.last_name.trim().length < 2) {
      newErrors.last_name = "Must contain at least 3 letters.";
    }

    // Email Validation
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Invalid email format.";
    }

    // Company Name Validation
    if (!formData.company.trim()) {
      newErrors.company = "Company Name is required.";
    } else if (!nameRegex.test(formData.company)) {
      newErrors.company = "Only letters and spaces are allowed.";
    } else if (formData.company.trim().length < 3) {
      newErrors.company = "Must contain at least 3 letters.";
    }

    // Message Validation
    if (!formData.message.trim()) newErrors.message = "Message is required.";
    else if (formData.message.trim().length < 10) {
      newErrors.message = "Message should be at least 10 characters.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  // Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    // Clear error when user starts typing
    setErrors({ ...errors, [e.target.name]: "" });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponseMessage("");

    if (!validateForm()) {
      return; // Stop submission if validation fails
    }

    setIsSubmitting(true);

    try {
      const response = await axios.post("https://dev-web-backend-318308205406.us-central1.run.app/saveForm", formData);

      if (response.status === 200) {
        setSnackbarOpen(true);
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          company: "",
          message: "",
        });
      } else {
        setResponseMessage("Failed to send message. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setResponseMessage("An error occurred. Please try again later.");
    }

    setIsSubmitting(false);
  };
  // Close the Snackbar popup
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  // Dynamic font sizes based on screen resolution
  const fontSizes = {
    heading: isLargeScreen ? 30 : isLaptop ? 24 : isMobile ? 16 : 18,
    title: isLargeScreen ? 68 : isLaptop ? 42 : isMobile ? 40 : 40,
    formFields: isLargeScreen ? 18 : isLaptop ? 18 : isMobile ? 18 : 18,
    cardsTitle: isLargeScreen ? 30 : isLaptop ? 22 : isMobile ? 22 : 22,
    cardBody: isLargeScreen ? 20 : isLaptop ? 20 : isMobile ? 16 : 16,
    buttonText: isLargeScreen ? 20 : isLaptop ? 16 : isMobile ? 14 : 16,
    bodyText: 18,
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        padding: "70px 0", // Ensures it stays centered vertically
        // Adds spacing at top and bottom
      }}
    >
      <Container
        maxWidth="lg" // Ensures it doesn't stretch too much on large screens
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          sx={{ maxWidth: "1400px" }} // Prevents stretching on large screens
        >
          {/* Contact Form Section */}
          <Grid item xs={12} md={7} lg={7}>
            <Paper
              elevation={0}
              sx={{
                p: { xs: 2, sm: 3, md: 4 },
                py: 25,
                backgroundColor: "#F2F3F5A6",
                borderRadius: "15px",
                paddingTop: "60px !important",
                paddingBottom: "60px !important",
              }}
            >
              <Typography
                align="center"
                sx={{
                  mb: 3,
                  fontSize: fontSizes.heading,

                  fontWeightLight: 400,
                }}
              >
                Let's Talk
              </Typography>

              <form onSubmit={handleSubmit}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: fontSizes.cardBody,

                        fontWeight: 300,
                      }}
                    >
                      First Name*
                    </Typography>
                    <TextField
                      fullWidth
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleChange}
                      variant="outlined"
                      error={!!errors.first_name}
                      helperText={errors.first_name}
                      required
                      sx={{
                        mt: 1,
                        backgroundColor: "white",
                        borderRadius: "10px",
                        "& .MuiFormHelperText-root": {
                          backgroundColor: "#F2F3F5A6 !important",
                          padding: "0px !important",
                          margin: "0px !important",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: fontSizes.cardBody,

                        fontWeight: 300,
                      }}
                    >
                      Last Name*
                    </Typography>
                    <TextField
                      fullWidth
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleChange}
                      variant="outlined"
                      error={!!errors.last_name}
                      helperText={errors.last_name}
                      required
                      sx={{
                        mt: 1,
                        backgroundColor: "white",
                        borderRadius: "10px",
                        "& .MuiFormHelperText-root": {
                          backgroundColor: "#F2F3F5A6 !important",
                          padding: "0px !important",
                          margin: "0px !important",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: fontSizes.cardBody,

                        fontWeight: 300,
                      }}
                    >
                      Corporate Email*
                    </Typography>
                    <TextField
                      fullWidth
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      variant="outlined"
                      error={!!errors.email}
                      helperText={errors.email}
                      required
                      sx={{
                        mt: 1,
                        backgroundColor: "white",
                        borderRadius: "10px",
                        "& .MuiFormHelperText-root": {
                          backgroundColor: "#F2F3F5A6 !important",
                          padding: "0px !important",
                          margin: "0px !important",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: fontSizes.cardBody,

                        fontWeight: 300,
                      }}
                    >
                      Company Name*
                    </Typography>
                    <TextField
                      fullWidth
                      name="company"
                      value={formData.company}
                      onChange={handleChange}
                      variant="outlined"
                      error={!!errors.company}
                      helperText={errors.company}
                      required
                      sx={{
                        mt: 1,
                        backgroundColor: "white",
                        borderRadius: "10px",
                        "& .MuiFormHelperText-root": {
                          backgroundColor: "#F2F3F5A6 !important",
                          padding: "0px !important",
                          margin: "0px !important",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: fontSizes.cardBody,

                        fontWeight: 300,
                      }}
                    >
                      Message*
                    </Typography>
                    <TextField
                      fullWidth
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      multiline
                      rows={6}
                      variant="outlined"
                      error={!!errors.message}
                      helperText={errors.message}
                      required
                      sx={{
                        mt: 1,
                        backgroundColor: "white",
                        borderRadius: "10px",
                        "& .MuiFormHelperText-root": {
                          backgroundColor: "#F2F3F5A6 !important",
                          padding: "0px !important",
                          margin: "0px !important",
                        },
                      }}
                    />
                  </Grid>

                  {/* Submit Button */}
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center", mt: 0.5 }}
                  >
                    <Button
                      type="submit"
                      variant="outlined"
                      disabled={isSubmitting}
                      sx={{
                        borderRadius: "50px",
                        border: "2px solid #0087B4",
                        color: "#0087B4",
                        padding: "10px 20px",
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        fontWeight: 380,
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#0087B4",
                          color: "#FFFFFFD9",
                        },
                      }}
                    >
                      {isSubmitting ? "Sending..." : "Send Message"}
                      <ArrowForward sx={{ marginLeft: "8px" }} />
                    </Button>
                  </Grid>

                  {/* Snackbar Popup for Success Message */}
                  <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={4000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  >
                    <Alert
                      onClose={handleCloseSnackbar}
                      severity="success"
                      sx={{ width: "100%" }}
                    >
                      Message sent successfully!
                    </Alert>
                  </Snackbar>
                </Grid>
              </form>
            </Paper>
          </Grid>

          {/* Contact Information Section */}
          <Grid item xs={12} md={5}>
            <Box sx={{ pl: { md: 10 }, mt: { xs: 2, md: 0 } }}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 300,
                  mb: 1,
                  fontSize: fontSizes.cardsTitle,
                }}
              >
                Contact Information
              </Typography>

              <Box sx={{ mb: 2 }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 300,
                    fontSize: fontSizes.cardBody,
                  }}
                >
                  Email: contact@devangles.com
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 300,
                    fontSize: fontSizes.cardBody,
                  }}
                >
                  Phone:+91-20-4863 6888
                </Typography>
              </Box>

              <Divider sx={{ my: 6 }} />

              <Typography
                variant="h6"
                sx={{
                  fontWeight: 300,
                  mb: 2,
                  fontSize: fontSizes.cardsTitle,
                }}
              >
                Office Locations
              </Typography>

              {/* Pune Office */}
              <Grid container spacing={4} alignItems="center" sx={{ mb: 6 }}>
                {/* Left Side: Google Maps (6 Columns) */}
                <Grid item xs={12} md={6}>
                  <iframe
                    title="Pune Office"
                    src={locations.Pune}
                    width="100%"
                    height="150px"
                    style={{ border: "none", borderRadius: "10px" }}
                    allowFullScreen=""
                    loading="lazy"
                  ></iframe>
                </Grid>

                {/* Right Side: Office Details (6 Columns) */}
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 300,
                      mb: 1,
                      fontSize: fontSizes.cardsTitle,
                    }}
                  >
                    Pune
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 300,
                      fontSize: fontSizes.bodyText,
                      lineHeight: 1.5,
                    }}
                  >
                    03A, 6th Floor, Downtown City Vista, Ashoka Nagar, Kharadi,
                    Pune, Maharashtra 411014.
                  </Typography>
                </Grid>
              </Grid>

              <Divider sx={{ my: 6 }} />

              {/* Ahmedabad Office */}
              <Grid container spacing={4} alignItems="center">
                {/* Left Side: Google Maps (6 Columns) */}
                <Grid item xs={12} md={6}>
                  <iframe
                    title="Ahmedabad Office"
                    src={locations.Ahmedabad}
                    width="100%"
                    height="150px"
                    style={{ border: "none", borderRadius: "10px" }}
                    allowFullScreen=""
                    loading="lazy"
                  ></iframe>
                </Grid>

                {/* Right Side: Office Details (6 Columns) */}
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 300,
                      mb: 1,
                      fontSize: fontSizes.cardsTitle,
                    }}
                  >
                    Ahmedabad
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 300,
                      fontSize: fontSizes.bodyText,
                      lineHeight: 1.5,
                    }}
                  >
                    212, Rudram Icon, Nr. Silver Oak University, Gota,
                    Ahmedabad, Gujarat 382481.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ContactFormComponent;
