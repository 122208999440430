import React from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FaD, FaE, FaV, FaA, FaN, FaG,  FaL, FaS } from "react-icons/fa6";

// Google's logo colors
const googleColors = ["#4285F4", "#EA4335", "#FBBC05", "#34A853"];

const CoreValues = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLaptop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lxl"));

  const getIconSize = () => {
    if (isMobile) return 42;
    if (isTablet || isLaptop) return 34;
    if (isLargeScreen) return 64;
    return 34;
  };

  const coreValues = [
    {
      letter: "D",
      value: "Dedication",
      description:
        "A commitment to clients and projects, ensuring high-quality results.",
      icon: <FaD size={getIconSize()} />,
    },
    {
      letter: "E",
      value: "Excellence",
      description: "Striving for the highest standards in all aspects of work.",
      icon: <FaE size={getIconSize()} />,
    },
    {
      letter: "V",
      value: "Vision",
      description:
        "Forward-thinking and anticipating future technology trends.",
      icon: <FaV size={getIconSize()} />,
    },
    {
      letter: "A",
      value: "Agility",
      description:
        "Quick and flexible response.",
      icon: <FaA size={getIconSize()} />,
    },
    {
      letter: "N",
      value: "Next-Gen",
      description:
        "Guiding clients towards future-proof solutions.",
      icon: <FaN size={getIconSize()} />,
    },
    {
      letter: "G",
      value: "Growth",
      description:
        "Fostering continuous improvement and expansion.",
      icon: <FaG size={getIconSize()} />,
    },
    {
      letter: "L",
      value: "Leadership",
      description:
        "Taking initiative and providing expert guidance.",
      icon: <FaL size={getIconSize()} />,
    },
    {
      letter: "E",
      value: "Efficiency",
      description:
        "Optimizing processes and delivering timely solutions.",
      icon: <FaE size={getIconSize()} />,
    },
    {
      letter: "S",
      value: "Solidity",
      description:
        "Reliable and strong foundations.",
      icon: <FaS size={getIconSize()} />,
    },
  ];

  return (
    <Box
      sx={{
        py: 10,
        backgroundColor: "#F2F3F5A6",
      }}
    >
      <Container maxWidth="xl">
        <Typography
          variant="h2"
          align="center"
          sx={{
            fontSize: isMobile ? "32px" : "42px",
            fontWeight: 400,
            mb: 6,
            color: "#000",
          }}
        >
          Our Core Values
        </Typography>

        {/* First Row (D, E, V) */}
        <Grid container spacing={4} justifyContent="center">
          {coreValues.slice(0, 3).map((value, index) => {
            const color = googleColors[index % googleColors.length]; // Rotate colors
            return (
              <Grid
                item
                xs={12}
                sm={4}
                md={2}
                key={index}
                sx={{ textAlign: "center" }}
              >
                <Card
                  elevation={0}
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "12px",
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    transition: "0.3s",
                    "&:hover": { transform: "translateY(-8px)" },
                  }}
                >
                  <CardContent sx={{ flexGrow: 1, textAlign: "center", p: 4 }}>
                    <Box
                      sx={{
                        mb: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: getIconSize() + 70,
                        height: getIconSize() + 70,
                        borderRadius: "50%",
                        backgroundColor: "#FFF",
                        boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                        margin: "0 auto",
                      }}
                    >
                      {React.cloneElement(value.icon, { color })}
                    </Box>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: isMobile ? "20px" : "26px",
                        fontWeight: 400,
                        color,
                        mt: 3,
                        mb: 1,
                      }}
                    >
                      {value.value}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: isMobile ? "16px" : "18px",
                        fontWeight: 300,
                        color: "#555",
                        lineHeight: 1.6,
                      }}
                    >
                      {value.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>

        {/* Second Row (Remaining Values) */}
        <Grid
          container
          spacing={4}
          justifyContent="center"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          {coreValues.slice(3).map((value, index) => {
            const color = googleColors[(index + 3) % googleColors.length]; // Rotate colors
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={2}
                key={index}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Card
                  elevation={0}
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "12px",
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    transition: "0.3s",
                    "&:hover": { transform: "translateY(-8px)" },
                  }}
                >
                  <CardContent sx={{ flexGrow: 1, textAlign: "center", p: 4 }}>
                    <Box
                      sx={{
                        mb: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: getIconSize() + 70,
                        height: getIconSize() + 70,
                        borderRadius: "50%",
                        backgroundColor: "#FFF",
                        boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                        margin: "0 auto",
                      }}
                    >
                      {React.cloneElement(value.icon, { color })}
                    </Box>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: isMobile ? "20px" : "26px",
                        fontWeight: 400,
                        color,
                        mt: 3,
                        mb: 1,
                      }}
                    >
                      {value.value}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: isMobile ? "16px" : "18px",
                        fontWeight: 300,
                        color: "#555",
                        lineHeight: 1.6,
                      }}
                    >
                      {value.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
};

export default CoreValues;
