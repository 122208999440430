import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/Routes";
import Layout from "./components/Layouts/Layout";
import ScrollToTop from './components/scrollTop';
import './global.css'
import { ThemeProvider } from "@mui/material/styles";
import theme from "./components/theme/Theme";
import { HelmetProvider } from "react-helmet-async";
import CookieConsentBanner from './components/coockies';
const App = () => {
  return (
    <HelmetProvider>
    <ThemeProvider theme={theme}> 
    <Router>
      <Layout>
        <AppRoutes />
        <ScrollToTop/>
        {/* <CookieConsentBanner /> */}
      </Layout>
    </Router>
    </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
  