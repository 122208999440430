import React from "react";
import Hero from "../components/about us/Hero";
import About from "../components/about us/About";
import CoreValues from "../components/about us/CoreValues";
import LetsTalkSection from "../components/Landing Page/letsTalkBox";
import { Helmet } from "react-helmet-async";
const Aboutus = () => {
  return (
    <>
      {/*metadata for SEO */}
      <Helmet>
        
        
        <title>About | DevAngles</title>
        <meta
          name="description"
          content="About DevAngles"
        />
        
        <link 
          rel="canonical"
          href="https://www.devangles.com/about/"
        />
        <link rel="dns-prefetch" href="https://www.devangles.com" />
        <link rel="dns-prefetch" href="//fonts.googleapis.com" />
      </Helmet>
      <Hero />
      <About />
      <CoreValues />
      <LetsTalkSection />
    </>
  );
};

export default Aboutus;
