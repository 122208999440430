import React from "react";
import { Container, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForward from "@mui/icons-material/ArrowForward";
const NotFound = () => {
  return (
    <Container 
      maxWidth="lg" 
      sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", height: "90vh", pt: 9, pb: 9 }}    >
      <Typography variant="h2" gutterBottom>
        404
      </Typography>
      <Typography variant="h5" gutterBottom>
        Page Not Found
      </Typography>
      <Typography variant="body1" paragraph>
        The page you are looking for does not exist.
      </Typography>
      <Button
              component={Link} // Turns button into a link
              to="/" // Redirects to the About page
              variant="outlined"
              sx={{
                borderRadius: "50px",
                border: "2px solid #0087B4",
                color: "#0087B4",
                padding: "10px 20px",
                textTransform: "none",
                fontSize: "18px",
                display: "inline-flex",
                alignItems: "center",
                gap: "8px",
                marginTop: "20px",

                transition:
                  "background-color 0.3s ease-in-out, color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "#0087B4",
                  color: "#FFFFFFD9",
                },
                "&:hover .MuiSvgIcon-root": {
                  transform: "translateX(-5px)",
                  opacity: 1,
                  transition:
                    "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
                },
                "&:hover .text": {
                  transform: "translateX(3px)",
                  transition: "transform 0.3s ease-in-out",
                },
              }}
            >
              <Typography
                className="text"
                sx={{
                  transition: "transform 0.3s ease-in-out",
                  fontWeight: 380,
                  fontSize: "18px",
                }}
              >
               Go to home
              </Typography>
              <ArrowForward
                sx={{
                  opacity: 0.6,
                  transition:
                    "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
                }}
              />
            </Button>
    </Container>
  );
};

export default NotFound;

